@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500&display=swap');
@layer base {
  html {
    font-family: Poppins, sans-serif;
  }
}
/* @font-face {
  font-family: 'hammer-regular';
  src: local('hammer-regular'),
    url('./App/assets/fonts/HammersmithOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto';
  src: local('roboto'),
    url('./App/assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto-bold';
  src: local('roboto-bold'),
    url('./App/assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto-light';
  src: local('roboto-light'),
    url('./App/assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'evolve-bold';
  src: local('evolve-bold'),
    url('./App/assets/fonts/evolve-bold.otf') format('opentype');
}

@font-face {
  font-family: 'evolve-medium';
  src: local('evolve-medium'),
    url('./App/assets/fonts/evolve-medium.otf') format('opentype');
}

@font-face {
  font-family: 'evolve-regular';
  src: local('evolve-regular'),
    url('./App/assets/fonts/evolve-regular.otf') format('opentype');
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', '|Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
